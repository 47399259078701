import { Button } from "@nextui-org/react";
import { Navbar } from "./exports";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="container mx-auto">
        <section aria-label="welcome message" className="bg-patito-500">
          <h1
            aria-label="we're working on it!"
            className="font-bold text-3xl text-center mt-10 text-slate-300"
          >
            We're working on it!
          </h1>
        </section>
      </div>
    </div>
  );
}

export default App;
